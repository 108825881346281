import React from 'react';
import Modal from '../../../components/Modal';
import Quote from '../../../components/Quote';

const media = {
  secTitle: 'Systems that Perpetuate Poverty',
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/aIgRBeTtzJ0',
  },
};

export default () => (
  <Modal closeTo="Learned" media={media}>
    <p className="Title--2 Title--c">
      Many attribute poverty to laziness or individual failings and decry those
      who receive government assistance, rather than asking why or taking to
      task the systems that perpetuate and benefit from poverty.
    </p>
    <p>
      The Pew Research Center found that public opinion is sharply divided when
      considering views of Americans living in poverty. About 51 percent of
      Americans believe that the government can’t afford to help those in need,
      whereas 43 percent say the government should help these people even if it
      means that the government increases its debt.
    </p>
    <p>
      Researchers tested these perceptions and found that when white Americans
      perceive threats to their status as the dominant demographic group, their
      resentment of people of color increases. In the study, when white
      Americans were made aware that they would no longer be the dominant
      demographic group in the coming decades, they became more resentful and
      their opposition of federal entitlement programs (e.g. welfare) increased.
      The researchers discovered that attitudes among whites and communities of
      color about welfare diverged in 2008 — the same year as the Great
      Recession and the presidential election of Barack Obama. This research
      suggests that whites’ perceptions that people of colors’ social standing
      is rising can produce periods of “welfare backlash” in which adoption of
      policies restricting or curtailing welfare programs is more likely.
    </p>
    <p>
      Black and white respondents in our interviews and focus groups offered
      varying reasons for why black people may have a harder time breaking out
      of poverty than white people.
    </p>
    <p>
      This tracks findings from Pew, who found that among those who said being
      black hurts a person’s ability to get ahead, black adults were much more
      likely than white adults (84 percent vs. 54 percent) to say that racial
      discrimination is a major reason for this disparity. Black respondents
      were also more likely to say that less access to high-paying jobs and good
      schools are major obstacles for black people. White respondents, in turn,
      were more likely than black respondents to say that family instability and
      a lack of good role models are major reasons why it may be harder for
      black people to get ahead; black and white adults who said being black
      hurts a person’s ability to get ahead were equally likely to say a lack of
      motivation to work hard is a major reason (22 percent).
    </p>
    <Quote
      quote="So there’s really a disdain for people who are impoverished. And it seems as though people here live in a bubble and not thinking about greater issues of this country with inequity and class differences and how poverty is such a huge, huge issue that affects so many people across race, across spectrum of well, ethnic groups."
      person="Black female"
      title="Montgomery"
    />
    <Quote
      quote="We’re sort of dealing with the outfall of inequitable pay and unfair wages by providing housing for the working poor without asking the question of why are the working poor....‘Oh, you could get a job in tech if you had stayed in school. So, you’re a hotel worker, so just suck it up.’"
      person="White male, 63"
      title="Charlotte"
    />
    <Quote
      quote="There’s a big, ‘pull yourself up by your bootstraps’ sort of mentality. You know, no handout, no welfare sort of mentality. And I don’t think there’s a commitment to see why these problems happen."
      person="White male, 36"
      title="Knoxville"
    />
    <Quote
      quote="White folks who were poor or working poor were vehemently opposed to anti-poverty programs. Because I think among a lot of white folks, there is a misconception about who these programs benefit."
      person="White male, 23"
      title="Montgomery"
    />
  </Modal>
);
